export const nav = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/about",
  },

  {
    id: 3,
    text: "contact",
    url: "/contact",
  },
];
export const category = [
  {
    id: 1,
    category: "Business Intelligence",
    title: "Data Driven Insights",
    cover: "../images/category/BI.jpg",
  },
  {
    id: 2,
    category: "Data Processing",
    title: "Automatic Classification",
    cover: "../images/category/DP.jpg",
  },
  {
    id: 3,
    category: "Performance Monitoring",
    title: "Strategic Decision-Making",
    cover: "../images/category/PM.jpg",
  },
  {
    id: 4,
    category: "Prediction & Analytics",
    title: "Data Forecasting with AI",
    cover: "../images/category/AI.jpg",
  },
];
export const blog = [
  {
    id: 1,
    title: "Core&Outline Erebus to Aether",
    desc: "How Core&Outline developed from a dark and mangled idea to becoming the next tech unicorn in Africa.",
    category: "AI",
    cover: "../images/blogs/b1.jpg",
    date: "APRIL 05, 2020",
  },

  // {
  //   id: 2,
  //   title: "Streamlined Data Processing & Organization with Core&Outline",
  //   desc: "Discuss the convenience of uploading files and data for automatic classification and assessment using AI. Emphasize how AI-driven data organization enhances accessibility and analysis",
  //   category: "Data Processing",
  //   cover: "../images/blogs/b2.jpg",
  //   date: "MARCH 15, 2021",
  // },
  // {
  //   id: 3,
  //   title: "Enhanced Performance Monitoring & Workflow Optimization",
  //   desc: "Explain how AI and ML algorithms track user behavior and automate tasks for improved efficiency. Showcase the benefits of data-driven insights in application performance.",
  //   category: "AI",
  //   cover: "../images/blogs/b3.jpg",
  //   date: "MARCH 15, 2023",
  // },
  // {
  //   id: 4,
  //   title: "Prediction for Company Growth ",
  //   desc: "Core&Outline uses AI to help businesses develop metrics for understanding, predicting, and working towards their goals",
  //   category: "Data Analytics",
  //   cover: "../images/blogs/b4.png",
  //   date: "February 20, 2023",
  // },
  // {
  //   id: 5,
  //   title: "Comprehensive Insights from Diverse Data Sets",
  //   desc: "AI and ML techniques are used to generate predictions and analytics from various data sources, enabling decision-makers to optimize strategies and stay competitive",
  //   category: "IT",
  //   cover: "../images/blogs/b5.png",
  //   date: "February 20, 2023",
  // },
  // {
  //   id: 6,
  //   title: "Workflow Optimization",
  //   desc: "The platform tracks user behavior, application performance, and other metrics to provide comprehensive analytics. It also automates repetitive tasks to improve operational efficiency",
  //   category: "Technology",
  //   cover: "../images/blogs/b6.jpg",
  //   date: "February 20, 2021",
  // },
];

export const blogPosts = [
  {
    id: 1,
    title: "How Slidebean tracks growth with Core&Outline",
    category: "Case Study",
    readTime: "3 mins read",
    excerpt: "If you see it you can act on it, says Caya, CEO. With no easy way to calculate and analyze metrics in the early days of Slidebean the …",
    author: "Greta Retsnik",
    featured: true
  },
  // Add more blog posts here
];
